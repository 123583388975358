.new-matches-list-mobile {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: #0e2f49;
}

.new-matches-list-mobile .cards {
  flex-grow: 1;
  z-index: 1;
}

.new-matches-list-mobile .cards-container {
  position: absolute;
  top: calc(50% - (220px));
  left: calc(50% - 150px);
}

.new-matches-list-mobile .back-button {
  position: absolute;
  top: 24px;
  left: 14px;
  width: 26px;
  height: 26px;
  background-color: #1b4b6b;
  border-radius: 13px;
  background-image: url("../../../../assets/images/back-arrow-matches.svg");
  background-repeat: no-repeat;
  z-index: 2;
}

.new-matches-list-mobile .back-button:hover {
  fill: white;
  background-color: #13b9b7;
}

.new-matches-list-mobile .angle-background {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-matches-list-mobile .angle-background svg {
  width: 100%;
  height: 100%;
  z-index: 0;
}
