.stats-mobile {
  height: calc(100% - 120px);
  background-color: #dce7ec;
  background-image: linear-gradient(to top, #dce7ec, #ffffff 85%);
}
.stats-mobile .candidates-stats-header {
  padding-bottom: 20px;
}

.stats-mobile .campaigns-stats-charts {
  padding: 20px 0;
}

.stats-mobile .campaigns-stats-header {
  padding: 20px 0;
}

.stats-mobile .campaigns-stats-figures-list {
  padding: 20px 0 0 0;
}
