.active-campaign-bar {
  background-color: var(--color-big-sur);
  padding: 5px 20px;
  z-index: 1;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.active-campaign-bar .title {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.0625rem;
  margin: 0 10px;
}
