.campaign-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 160px;
  background-color: var(--color-child-of-light);
}

.campaign-list .request-campaign {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-magic-wand);
  padding: 8px 10px;
}

.campaign-list .content .wrapper {
  padding-left: 10px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
