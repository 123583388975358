.change-password-form button {
  align-self: center;
  margin-top: 30px;
}

.change-password-form .column {
  flex-basis: 33.33%;
}

.change-password-form .standard-input-field {
  margin: 6px 30px;
}

@media only screen and (max-width: 1169px) {
  .change-password-form .column {
    flex-basis: 100%;
  }
}
