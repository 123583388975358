.candidate-preview-card-mobile {
  font-family: "Roboto", sans-serif;
  display: block;
  position: fixed;
  padding: 20px 20px;
  color: var(--color-happy-days);
  background-color: var(--color-white);
  overflow-y: auto;
  z-index: 3;
}

.candidate-preview-card-mobile .personal-info-row {
  padding: 16px 0;
}

.candidate-preview-card-mobile .personal-info-col {
  display: flex;
  flex-grow: 1;
  flex-basis: 240px;
  margin-bottom: 24px;
}

.candidate-preview-card-mobile .hired-date-row {
  flex-grow: 1;
  padding: 12px 0 0 0;
}

.candidate-preview-card-mobile .photo-info-container {
  position: relative;
  width: 100px;
  margin-right: 24px;
}

.candidate-preview-card-mobile .photo-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 24px;
}

.candidate-preview-card-mobile .experience {
  display: block;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 0.75rem;
  letter-spacing: 0.6px;
  color: var(--color-boyzone);
}

.candidate-preview-card-mobile .chevron {
  position: absolute;
  top: 72px;
  right: -4px;
  z-index: 9999;
}

.candidate-preview-card-mobile .personal-info-col .culture-fit {
  display: block;
  font-size: 0.5625rem;
  font-weight: 500;
  line-height: 0.6875rem;
  margin-top: 12px;
  margin-bottom: 3px;
  text-align: center;
}

.candidate-preview-card-mobile .personal-info-col .star-icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.candidate-preview-card-mobile .personal-info-col .star-icon {
  margin: 0 2px;
}

.candidate-preview-card-mobile .personal-info-col .star-icon.active {
  fill: var(--color-super-rare-jade);
}

.candidate-preview-card-mobile .personal-info-col .first-name,
.candidate-preview-card-mobile .personal-info-col .last-name {
  display: block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.0625rem;
  margin-bottom: 3px;
}

.candidate-preview-card-mobile .personal-info-col .title {
  display: block;
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 0.9375rem;
  margin-top: 13px;
  margin-bottom: 4px;
}

.candidate-preview-card-mobile .personal-info-col .location {
  display: block;
  font-size: 0.75em;
  line-height: 0.875rem;
  margin-bottom: 12px;
}

.candidate-preview-card-mobile .education-info-col,
.candidate-preview-card-mobile .skills-info-col,
.candidate-preview-card-mobile .hobbies-info-col {
  flex-grow: 1;
  flex-basis: 240px;
}

.candidate-preview-card-mobile .skills-info-col,
.candidate-preview-card-mobile .hobbies-info-col {
  margin-bottom: 28px;
}

.candidate-preview-card-mobile .skills-info-col .title,
.candidate-preview-card-mobile .hobbies-info-col .title {
  display: block;
  font-size: 0.75em;
  line-height: 0.9375rem;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 10px;
}

.candidate-preview-card-mobile .skills-info-col .skill,
.candidate-preview-card-mobile .hobbies-info-col .hobby {
  display: block;
  font-size: 0.75rem;
  line-height: 1.0625rem;
}

.candidate-preview-card-mobile .education-info-col .title {
  display: block;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  margin-bottom: 9px;
}

.candidate-preview-card-mobile .education-info-col .institution {
  display: block;
  font-size: 0.6875em;
  line-height: 0.8125rem;
  margin-bottom: 3px;
}

.candidate-preview-card-mobile .education-info-col .degree,
.candidate-preview-card-mobile .education-info-col .more {
  display: block;
  font-size: 0.8125em;
  line-height: 0.9375rem;
}

.candidate-preview-card-mobile .education-info-col .more {
  margin-top: 7px;
}

.candidate-preview-card-mobile .education-info-col .view-resume-btn {
  margin-top: 24px;
}

.candidate-preview-card-mobile .hired-date {
  display: block;
  width: 124px;
  font-size: 0.625em;
  line-height: 0.8125rem;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: var(--color-ending-navy-blue);
}

.candidate-preview-card-mobile .action-icon,
.candidate-preview-card-mobile .briefcase-icon {
  width: 18px;
  height: 18px;
}

.candidate-preview-card-mobile .briefcase-icon {
  fill: var(--color-super-rare-jade);
}

.candidate-preview-card-mobile .action-icon.selected {
  fill: var(--color-brig);
}

.candidate-preview-card-mobile .divider {
  opacity: 0.5;
}

.candidate-preview-card-mobile .social-icons {
  display: inline-block;
  height: 18px;
  margin-top: 10px;
}

.candidate-preview-card-mobile .social-icons svg {
  width: 18px;
  height: 18px;
  margin-right: 14px;
}

.candidate-preview-card-mobile .favorite-icon__container {
  width: 18px;
  height: 18px;
  margin-left: 20px;
}

.candidate-preview-card-mobile .status-icon__container {
  width: 18px;
  height: 18px;
}
