.button {
  font-family: "Lato", sans-serif;
  min-width: 120px;
  font-size: 0.6875rem;
  letter-spacing: 0.6px;
  line-height: 0.8125rem;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 22px;
  border: 0;
  border-radius: 3px;
  background-color: var(--color-brig);
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
}

.button:focus-visible {
  outline: 2px solid var(--color-forest-blues);
}

.button:hover {
  cursor: pointer;
  filter: brightness(110%);
}

.button[disabled] {
  filter: opacity(0.5);
}

.button--green {
  background-color: var(--color-super-rare-jade);
}

.button--pink {
  background-color: var(--color-raspberry-glaze);
}

.button > svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: var(--color-white);
}
