.login-form .sign-up {
  color: var(--color-super-rare-jade);
}

.login-form button {
  margin: 40px 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.underlined-input-field input {
  height: 3rem;
}
