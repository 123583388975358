.top-navigation {
  font-family: "Roboto", sans-serif;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  font-size: 1rem;
  background-color: var(--color-forest-blues);
  z-index: 1;
}

.top-navigation__left,
.top-navigation__logo,
.top-navigation__right,
.top-navigation__toggle,
.top-navigation__profile {
  display: flex;
  align-items: center;
}

.top-navigation__left,
.top-navigation__logo,
.top-navigation__right {
  flex-grow: 1;
  width: 33.33%;
}

.top-navigation__toggle,
.top-navigation__profile {
  flex-shrink: 0;
}

.top-navigation__left .back-icon {
  width: 16px;
  height: 16px;
}

.top-navigation__logo svg:hover {
  filter: brightness(110%);
}

.top-navigation__right {
  flex-shrink: 0;
  justify-content: flex-end;
  order: 3;
}

.top-navigation__profile:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-blissful-blue);
}

.top-navigation__profile img {
  width: 1.875em;
  height: 1.875em;
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.top-navigation__profile span {
  margin-left: 1.25em;
  font-size: 0.75em;
  flex-shrink: 0;
  color: var(--color-blissful-blue);
}

.ai-switch {
  display: inherit;
  padding: 0 24px 0 4px;
}

@media only screen and (min-width: 1170px) {
  .ai-switch {
    padding: 0 78px 0 4px;
  }

  .top-navigation__logo {
    justify-content: center;
  }
}
