.home-mobile {
  height: calc(100vh - 50px - 46px);
}

.home-mobile .bottom-navigation {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 46px;
  padding: 0 30px;
  background-color: #ebf5f9;
  -webkit-box-shadow: 0px -2px 6px 0px #265d8429;
  -moz-box-shadow: 0px -2px 6px 0px #265d8429;
  box-shadow: 0px -2px 6px 0px #265d8429;
  z-index: 1;
}

.home-mobile .bottom-navigation svg {
  margin: 10px;
}

.home-mobile .bottom-navigation .selected-campaign-indicator {
  position: absolute;
  bottom: 5px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-super-rare-jade);
}
