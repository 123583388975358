.candidate-list-mobile__wrapper {
  background-color: #dde8ee;
  overflow: hidden;
}

.candidate-list-mobile {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}
.candidate-list-mobile__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(190px, auto);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px 20px 20px 20px;
}

.candidate-list-mobile__wrapper .new-match-bar-container {
  z-index: 1;
}

@media only screen and (max-width: 666px) {
  .candidate-list-mobile__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 667px) {
  .candidate-list-mobile__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
