.candidate-list {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}
.candidate-list__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(190px, auto);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px 20px;
}

@media only screen and (max-width: 1280px) {
  .candidate-list__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .candidate-list__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1921px) {
  .candidate-list__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
