.search-filter-bar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 3.125rem;
  padding: 0 20px;
  background-color: var(--color-moby-dick);
  z-index: 2;
}

.search-filter-bar__total {
  font-family: "Roboto", sans-serif;
  color: var(--color-wedgewood);
  padding-right: 20px;
  font-weight: 500;
  font-size: 0.8125rem;
  letter-spacing: 0.3px;
}

.search-filter-bar__search {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search-filter-bar__search .search-icon {
  pointer-events: none;
  position: absolute;
  left: 6px;
}

.search-filter-bar__search input {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: var(--color-wedgewood);
  width: 100%;
  max-width: 18.75rem;
  min-width: 9.375rem;
  height: 1.875rem;
  border-radius: 0.1875rem;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  padding-left: 2.125rem;
  outline: none;
}

.search-filter-bar__search input:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  border: 1px solid var(--color-blissful-blue);
}

.search-filter-bar__search input:focus {
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  border: 1px solid var(--color-high-blue);
}

.search-filter-bar__filters {
  display: flex;
  align-items: center;
}

.search-filter-bar__filters .filter-icon {
  cursor: pointer;
  outline: none;
}

.search-filter-bar__filters .filter-icon.selected {
  fill: var(--color-brig);
}

.search-filter-bar__filters .filter-icon:hover {
  transform: scale(1.15);
  filter: brightness(95%);
}

.search-filter-bar__filters .filter-icon:not(:last-of-type) {
  margin-right: 30px;
}

.search-filter-bar__filters .filter-icon:last-of-type,
.search-filter-bar__filters .filter-icon:first-of-type {
  margin-left: 20px;
}

.search-filter-bar__filters .vertical-spacer {
  border-left: 1px solid var(--color-blissful-blue);
  height: 24px;
}
