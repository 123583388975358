.account-details-form {
  width: 100%;
  max-width: 1080px;
}

.account-details-form__user-info .first-row {
  display: flex;
  flex-wrap: wrap;
}

.account-details-form__user-info .first-row > div {
  flex-grow: 1;
  flex-basis: 33.33%;
}

.form-fields_column .standard-input-field {
  padding: 6px 30px;
}

.profile-photo__column,
.profile-photo__container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-photo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  background-color: var(--color-blissful-blue);
  border-radius: 100%;
  object-fit: cover;
}

.upload-user-photo-icon,
.remove-user-photo-icon {
  position: absolute;
  left: 102px;
  top: 102px;
  width: 16px;
  height: 16px;
}

.user-photo__action-icon-container {
  width: 110px;
  height: 110px;
  position: absolute;
}

.account-details-form__user-info .second-row {
  display: flex;
  justify-content: center;
}

.account-details-form__user-info .second-row button {
  margin-top: 24px;
}

#upload-user-photo-field {
  display: none;
}

@media only screen and (max-width: 1169px) {
  .profile-photo__column {
    padding: 0 30px 20px 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .account-details-form__user-info .first-row > div {
    flex-basis: 100%;
  }
}
