.create-password-form .password-policy {
  color: var(--color-blue-heaven);
}

.create-password-form .password-policy p {
  font-size: 0.875rem;
  letter-spacing: 0.6px;
  font-weight: 600;
  margin: 24px 0;
}
