.underlined-input-field input {
  background-color: transparent;
  font-size: inherit;
  color: var(--color-wedgewood);
  width: 100%;
  min-width: 9.375rem;
  border-bottom: 1px solid var(--color-brig);
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  outline: none;
}

.underlined-input-field input.invalid {
  border-bottom: 1px solid var(--color-raspberry-glaze);
  -webkit-box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
  -moz-box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
  box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
}

.underlined-input-field input:hover.invalid,
.underlined-input-field input:focus.invalid {
  -webkit-box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
  -moz-box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
  box-shadow: 0px 1px 0px 0px var(--color-raspberry-glaze);
  border-bottom: 1px solid var(--color-raspberry-glaze);
}

.underlined-input-field input.valid,
.underlined-input-field input:hover.valid,
.underlined-input-field input:focus.valid {
  -webkit-box-shadow: 0px 1px 0px 0px var(--color-teal-me-no-lies);
  -moz-box-shadow: 0px 1px 0px 0px var(--color-teal-me-no-lies);
  box-shadow: 0px 1px 0px 0px var(--color-teal-me-no-lies);
  border-bottom: 1px solid var(--color-teal-me-no-lies);
}

.underlined-input-field input:hover {
  -webkit-box-shadow: 0px 1px 0px 0px var(--color-brig);
  -moz-box-shadow: 0px 1px 0px 0px var(--color-brig);
  box-shadow: 0px 1px 0px 0px var(--color-brig);
  border-bottom: 1px solid var(--color-brig);
}

.underlined-input-field input:focus {
  background-color: rgba(20, 185, 184, 0.1);
  -webkit-box-shadow: 0px 1px 0px 0px var(--color-brig);
  -moz-box-shadow: 0px 1px 0px 0px var(--color-brig);
  box-shadow: 0px 1px 0px 0px var(--color-brig);
  border-bottom: 1px solid var(--color-brig);
}

.underlined-input-field .label {
  display: block;
  color: var(--color-wedgewood);
  padding-bottom: 4px;
  text-align: left;
}

.underlined-input-field .error-msg {
  color: var(--color-raspberry-glaze);
  font-size: 13px;
  min-height: 19px;
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: left;
}

.underlined-input-field input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-blissful-blue);
  opacity: 1; /* Firefox */
}

.underlined-input-field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-blissful-blue);
}

.underlined-input-field input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-blissful-blue);
}

.underlined-input-field .password-field-container {
  position: relative;
}

.underlined-input-field .password-field-container input {
  padding-right: 48px;
}

.underlined-input-field .password-field-container .visibility-icon {
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
