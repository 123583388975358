.account {
  display: flex;
  flex-direction: column;
  background-color: var(--color-moby-dick);
  overflow-y: scroll;
  flex-grow: 1;
  align-items: center;
  padding: 34px 0;
  height: calc(100vh - 50px);
}

.account hr {
  width: auto;
  margin: 34px 30px 0 30px;
}

.account__logout {
  align-self: flex-end;
  margin-right: 30px;
}

.account .change-password {
  padding: 34px 0 0 0;
  width: 100%;
  max-width: 1080px;
}
