.create-new-password-form button {
  margin-top: 30px;
  margin-bottom: 60px;
}

.create-new-password-form button {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.create-new-password-form .password-policy {
  color: var(--color-blue-heaven);
  margin-top: 67px;
}

.create-new-password-form .password-policy p {
  font-size: 0.875rem;
  letter-spacing: 0.6px;
  font-weight: 600;
  margin: 24px 0;
}
