.home {
  height: calc(100vh - 50px);
}

.home__left-sidebar {
  -webkit-box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.04);
  z-index: 1;
}
.home__content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
}

.home__content-container {
  background-color: #133b58;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.home__content {
  background-color: var(--color-moby-dick);
  z-index: 0;
}

.new-match {
  position: relative;
  background: #0e2f49;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.home__graph-container {
  height: 160px;
  padding-top: 10px;
  padding-right: 20px;
  z-index: 1;
}

.home__right-sidebar {
  background-color: var(--color-child-of-light);
  z-index: 1;
  -webkit-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.04);
  box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.04);
}

/* Custom Scrollbar Bug Fix (removing empty space in place of horizontal scrollbar) */
#campaignList > div:first-of-type,
#candidateList > div:first-of-type,
#upcomingInterviewList > div:first-of-type,
#statsSidebar > div:first-of-type {
  margin-bottom: -15px !important;
}
