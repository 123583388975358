.candidates-stats-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 6px;
}

.candidates-stats-card__figure-box {
  display: flex;
  flex-direction: column;
  width: 3.375rem;
  height: 44px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--color-white);
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  border-color: var(--color-blissful-blue);
}

.candidates-stats-card__figure {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 1.4375rem;
  letter-spacing: 0.57px;
  color: var(--color-suez-canal);
  font-weight: 700;
}

.candidates-stats-card__title {
  font-family: "Roboto", sans-serif;
  color: var(--color-suez-canal);
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  font-weight: 500;
  margin-top: 6px;
}

.candidates-stats-card__container .indicator {
  width: 54px;
  height: 7px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
