.standard-input-field {
  font-family: "Roboto", sans-serif;
}

.standard-input-field input {
  font-size: 0.8125rem;
  color: var(--color-wedgewood);
  background-color: #f3fcff;
  width: 100%;
  min-width: 9.375rem;
  height: 1.875rem;
  border-radius: 0.1875rem;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  padding: 0 10px;
  outline: none;
}

.standard-input-field input.invalid {
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
  box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
}

.standard-input-field input:hover.invalid,
.standard-input-field input:focus.invalid {
  border: 1px solid var(--color-raspberry-glaze);
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
  box-shadow: 0px 0px 0px 1px var(--color-raspberry-glaze);
}

.standard-input-field input:hover.valid,
.standard-input-field input:focus.valid {
  border: 1px solid var(--color-teal-me-no-lies);
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-teal-me-no-lies);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-teal-me-no-lies);
  box-shadow: 0px 0px 0px 1px var(--color-teal-me-no-lies);
}

.standard-input-field input:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  border: 1px solid var(--color-blissful-blue);
}

.standard-input-field input:focus {
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  border: 1px solid var(--color-high-blue);
}

.standard-input-field .label {
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  font-weight: 500;
  display: block;
  color: var(--color-wedgewood);
  padding-bottom: 4px;
  text-align: left;
}
