@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";

:root {
  /* Get color names via: https://colornamer.robertcooper.me/ */
  --color-white: #ffffff;
  --color-raspberry-glaze: #ff7bac;
  --color-teal-me-no-lies: #08adac;
  --color-super-rare-jade: #14b9b8;
  --color-child-of-light: #f0f5f7;
  --color-moby-dick: #dde8ee;
  --color-blissful-blue: #b2c8d8;
  --color-big-sur: #b3ccdb;
  --color-batch-blue: #85b3cd;
  --color-brig: #509ec6;
  --color-suez-canal: #265d84;
  --color-incremental-blue: #0e3557;
  --color-forest-blues: #134067;
  --color-ending-navy-blue: #18395d;
  --color-happy-days: #526c80;
  --color-blue-heaven: #5b7e95;
  --color-first-frost: #cfe6f2;
  --color-submarine-grey: #4d565c;
  --color-morning-mist: #e6edf1;
  --color-two-harbours: #bed3e0;
  --color-ashen-wind: #94aab8;
  --color-boyzone: #2e9dd7;
  --color-pale-grey-blue: #d5e3ec;
  --color-wedgewood: #4b6e88;
  --color-pattens-blue: #d4e5ee;
  --color-blue-chrysocolla: #76b3d1;
  --color-high-blue: #4bb2e7;
  --color-halite-blue: #0a2a46;
  --color-empire-blue: #6192b2;
  --color-blue-martina: #21d4d2;
  --color-notable-hue: #89aabb;
  --color-drakes-neck: #2d6589;
  --color-hei-se-black: #16202f;
  --color-magic-wand: #c3d9e5;
  --color-phenomenon: #3c7498;
  --color-gloomy-sea: #4c677b;
}

/* Lato */
@font-face {
  font-family: Lato;
  src: url("./assets/fonts/Lato/Lato-Light.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("./assets/fonts/Lato/Lato-Regular.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("./assets/fonts/Lato/Lato-Semibold.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("./assets/fonts/Lato/Lato-Bold.woff2") format("woff2"),
    url("./assets/fonts/Lato/Lato-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/* Roboto */
@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-halite-blue);
}

.App {
  width: inherit;
  height: inherit;
  background-color: var(--color-moby-dick);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-size: 16px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

:focus {
  outline-color: var(--color-forest-blues);
}

.text-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.state-transition {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.clickable:hover {
  cursor: pointer;
  filter: brightness(110%);
}

.pointer {
  cursor: pointer;
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

hr {
  width: 100%;
  margin: 0;
  border-top: 1px solid var(--color-blissful-blue);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

/**
 * React Tooltip fade in/out effect
 */
.__react_component_tooltip {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-out !important;
  opacity: 0 !important;
  visibility: visible;
}

.__react_component_tooltip.show {
  visibility: visible;
  opacity: 1 !important;
}

/**
 * Calendly
*/
.calendly-overlay {
  background-color: rgba(38, 93, 132, 0.5) !important;
}

/**
 * Tooltip
*/
.custom__tooltip {
  font-size: 10px !important;
  border-radius: 3.75rem !important;
  opacity: 1 !important;
  padding: 4px 8px !important;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}

/**
 * Scrollbar styling
 */

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar:horizontal {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background: #13406733;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover:horizontal {
  background: #1340674d;
}
