.login-container {
  padding: 60px 0;
}

.login-container .title-section {
  padding-bottom: 80px;
}

.login-container .title-section .logo {
  margin-bottom: 40px;
}

.login-container .title-section h1 {
  font-size: 2.125rem;
  margin-top: 0;
  margin-bottom: 14px;
}

.login-container .title-section h3 {
  margin: 0;
  font-weight: 600;
}

.login-container form {
  width: 90%;
  max-width: 600px;
}
