.campaigns-stats-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
}

.campaigns-stats-card__figure-box {
  display: flex;
  flex-direction: column;
  width: 3.125rem;
  height: 2.875rem;
  border-radius: 5px;
}

.campaigns-stats-card__figure {
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 1.4375rem;
  letter-spacing: 0.57px;
  color: var(--color-white);
  font-weight: 700;
}

.campaigns-stats-card__title {
  font-family: "Roboto", sans-serif;
  color: var(--color-suez-canal);
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  font-weight: 500;
  margin-top: 6px;
}
