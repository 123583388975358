.login {
  overflow: auto;
  background-color: var(--color-white);
  height: inherit;
}

.login a,
.login .link {
  color: var(--color-happy-days);
  font-weight: 600;
}

.login .link:hover {
  text-decoration: underline;
}

.login .content {
  color: var(--color-forest-blues);
}

.login .back-to-site {
  margin-top: 20px;
}

.login .back-to-site svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: var(--color-happy-days);
}

.login .banner-overlay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  background-image: linear-gradient(to top, #052f53, rgba(1, 27, 46, 0) 75%);
  height: 100%;
  padding: 80px;
}

.login .banner {
  color: var(--color-white);
  background-image: url("../../assets/images/login-page-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.login .banner h1 {
  font-size: 2.375rem;
  margin-top: 0;
}

.login .banner p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.58px;
  margin-top: 40px;
  margin-bottom: 0;
}

.login .banner hr {
  width: 40px;
  border-top-width: 2px;
  border-color: var(--color-super-rare-jade);
}
