.two-step-verification-form {
  margin: 0 30px;
}
.two-step-verification-form button {
  margin-top: 30px;
  margin-bottom: 60px;
}

.two-step-verification-form .subtitle {
  font-size: 1.125em;
  line-height: 2.3125rem;
  font-weight: 600;
}

.two-step-verification-form button {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.two-step-verification-form .send-again > span {
  font-weight: 600;
  color: var(--color-magic-wand);
}

.two-step-verification-form .send-again > span.clickable {
  color: var(--color-super-rare-jade);
}
