.avatar {
  overflow: hidden;
}

.avatar > img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  object-fit: cover;
}

.avatar > svg {
  display: block;
  margin: 0 auto;
  transform: scale(0.5);
}
