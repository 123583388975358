.upcoming-interview-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 160px;
  background-color: var(--color-suez-canal);
}

.upcoming-interview-list .header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 35px;
  font-size: 0.8125em;
  font-weight: 600;
  letter-spacing: 0.33px;
  line-height: 0.8125rem;
  color: var(--color-white);
  background-color: var(--color-brig);
}

.upcoming-interview-list .content {
  overflow-y: auto;
  padding: 10px;
}

.upcoming-interview-list .content .wrapper {
  padding: 5px 10px;
}
