.stats-sidebar {
  background-color: var(--color-child-of-light);
}
.stats-sidebar .candidates-stats-header {
  padding-bottom: 20px;
}

.stats-sidebar .campaigns-stats-charts {
  padding: 20px 0;
}

.stats-sidebar .campaigns-stats-header {
  padding: 20px 0;
}

.stats-sidebar .campaigns-stats-figures-list {
  padding: 20px 0 0 0;
}
