.campaign-card {
  font-family: "Roboto", sans-serif;
  position: relative;
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 9px 15px 12px 15px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  font-size: 1rem;
}

.campaign-card:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  border: 1px solid var(--color-high-blue);
}

.campaign-card.selected {
  -webkit-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  -moz-box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  box-shadow: 0px 0px 0px 1px var(--color-high-blue);
  border: 1px solid var(--color-high-blue);
}

.campaign-card__header {
  display: flex;
  justify-content: space-between;
}

.campaign-card__name {
  width: 75%;
  color: var(--color-suez-canal);
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 1.0625rem;
}

.campaign-card__date {
  color: var(--color-hei-se-black);
  font-size: 0.6875em;
  line-height: 0.8125rem;
}

.campaign-card__description {
  color: var(--color-submarine-grey);
  font-size: 0.75em;
  letter-spacing: 0.3px;
  line-height: 0.9375rem;
  margin-right: 12px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.campaign-card__play-icon,
.campaign-card__pause-icon {
  cursor: pointer;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.campaign-card__play-icon:hover {
  fill: var(--color-super-rare-jade);
}

.campaign-card__pause-icon:hover {
  fill: var(--color-brig);
}

.campaign-card:last-of-type {
  margin-bottom: 0;
}
