.search-filter-bar-mobile {
  height: 2.75rem;
  padding: 0 20px;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(38, 93, 132, 0.16);
  -moz-box-shadow: 0px 2px 3px 0px rgba(38, 93, 132, 0.16);
  box-shadow: 0px 2px 3px 0px rgba(38, 93, 132, 0.16);
}

.search-filter-bar-mobile .clear-icon {
  width: 24px;
  height: 24px;
}

.search-filter-bar-mobile .search-field {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: var(--color-wedgewood);
  width: 100%;
  min-width: 9.375rem;
  height: 1.875rem;
  border: 0;
  background-color: transparent;
  padding-left: 0.5rem;
  outline: none;
}

.search-filter-bar-mobile__filters .filter-icon svg {
  width: 18px;
  height: 18px;
}

.search-filter-bar-mobile__filters .filter-icon.selected svg {
  fill: var(--color-brig);
}

.search-filter-bar-mobile__filters .filter-icon:not(:last-of-type) {
  margin-right: 30px;
}

.search-filter-bar-mobile__filters .filter-icon:nth-of-type(3) {
  margin-right: 20px;
}

.search-filter-bar-mobile__filters .filter-icon:last-of-type,
.search-filter-bar-mobile__filters .filter-icon:first-of-type {
  margin-left: 20px;
}

.search-filter-bar-mobile__filters .vertical-spacer {
  border-left: 1px solid var(--color-blissful-blue);
  height: 14px;
}
