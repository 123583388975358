.congratulations {
  font-weight: 700;
}

.congratulations .message-container {
  margin-bottom: 60px;
}

.congratulations .message-container p {
  color: var(--color-gloomy-sea);
}

.congratulations .contact-us-container p {
  margin-bottom: 30px;
  color: var(--color-gloomy-sea);
}

.congratulations .contact-us-container span {
  color: var(--color-super-rare-jade);
}

.congratulations .contact-us-container a {
  display: block;
  margin: 10px 0;
}
