.new-matches-list {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.new-matches-list .cards {
  flex-grow: 1;
  z-index: 1;
}

.new-matches-list .cards-container {
  position: absolute;
  top: calc(50% - (220px));
  left: calc(50% - 150px);
}

.dislike-icon {
  position: relative;
  width: 32px;
  height: 32px;
  left: calc(50% - (16px + 150px + 60px));
  display: block;
}

.like-icon {
  position: relative;
  width: 32px;
  height: 32px;
  right: calc(50% - (16px + 150px + 60px));
  display: block;
}

.new-matches-list .back-button {
  position: absolute;
  top: 28px;
  left: 24px;
  width: 26px;
  height: 26px;
  background-color: rgba(75, 178, 231, 0.3);
  border-radius: 13px;
  background-image: url("../../../assets/images/back-arrow-matches.svg");
  background-repeat: no-repeat;
}

.new-matches-list .back-button:hover {
  fill: white;
  background-color: #13b9b7;
}

.new-matches-list .angle-background {
  position: absolute;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-matches-list .angle-background svg {
  width: 100%;
  height: 100%;
  z-index: 0;
}
