.new-match-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-suez-canal);
  padding: 10px 20px;
  z-index: 2;
}

.new-match-bar__message {
  display: flex;
  align-items: center;
}

.new-match-bar__message > .icon {
  fill: var(--color-first-frost);
}

.new-match-bar__message > .title {
  color: var(--color-first-frost);
  font-size: 0.875rem;
  padding-left: 10px;
  padding-right: 20px;
  line-height: 1.125rem;
}
