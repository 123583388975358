.stats-section-header {
  color: var(--color-blue-heaven);
  display: flex;
  align-items: center;
}

.stats-section-header__icon {
  fill: var(--color-blissful-blue);
}

.stats-section-header__figure {
  font-family: "Roboto", sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 0 12px;
}

.stats-section-header__title {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.37px;
  white-space: pre;
}
