.empty-candidate-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-ashen-wind);
  border-radius: 5px;
  border: 1px solid var(--color-blissful-blue);
}

.empty-candidate-list-item .user-icon {
  fill: var(--color-big-sur);
  width: 64px;
  height: 64px;
}

.empty-candidate-list-item .title {
  font-family: "Roboto", sans-serif;
  display: block;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
}
