.register {
  background-color: var(--color-white);
  width: inherit;
  height: inherit;
  display: flex;
}

.register a {
  color: var(--color-happy-days);
  font-weight: 600;
}

.register .left-half {
  color: var(--color-white);
  background-color: var(--color-forest-blues);
}

.register .left-half .artwork {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0.35s ease-in-out 0.2s;
}

.register .left-half h1 {
  font-size: 2.375rem;
  margin-bottom: 0;
}

.register .left-half hr {
  width: 40px;
  border-top-width: 2px;
  border-color: var(--color-super-rare-jade);
}

.register .left-half .logo {
  height: 80px;
}

.register .right-half {
  flex-grow: 1;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.register .right-half .content {
  background-color: var(--color-white);
  color: var(--color-forest-blues);
  overflow-y: hidden;
}

.register .right-half .register-form-content {
  background-color: var(--color-white);
  color: var(--color-forest-blues);
  overflow-y: scroll;
  padding: 40px 10%;
}

.register .right-half .header {
  min-height: 80px;
}

.register .right-half .header,
.register .right-half .footer {
  color: var(--color-first-frost);
  padding: 20px 0;
  background-color: var(--color-forest-blues);
}

.register .right-half .back-to-site {
  color: var(--color-first-frost);
}
.register .right-half .back-to-site svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: var(--color-super-rare-jade);
}

.register .right-half .sign-in {
  color: var(--color-super-rare-jade);
  margin-left: 4px;
}

.register .right-half .step-indicator p {
  color: var(--color-suez-canal);
  margin-bottom: 4px;
  transition: 0.35s ease-in-out;
  font-size: 0.875rem;
}

.register .right-half .step-indicator div {
  flex-basis: 0;
  margin: 0 3px;
}

.register .right-half .step-indicator div.active-step span {
  background-color: var(--color-super-rare-jade);
}

.register .right-half .step-indicator div.active-step p {
  color: var(--color-white);
}

.register .right-half .step-indicator span {
  display: block;
  min-width: 12px;
  height: 4px;
  background-color: var(--color-suez-canal);
}

.register .right-half .step-indicator div:first-child span {
  border-radius: 4px 0 0 4px;
  margin-left: 0;
}

.register .right-half .step-indicator div:last-child span {
  border-radius: 0 4px 4px 0;
}
