.new-matches-card {
  font-family: "Roboto", sans-serif;
  user-select: none;
}

.new-matches-card .personal-info-row {
  align-self: flex-start;
  padding: 20px;
  color: var(--color-happy-days);
}

.new-matches-card .photo-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.new-matches-card .chevron {
  position: absolute;
  top: 64px;
  right: 0px;
  z-index: 1;
}

.new-matches-card .first-name,
.new-matches-card .last-name {
  display: block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.0625rem;
  margin-bottom: 3px;
}

.new-matches-card .personal-info .title {
  display: block;
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 0.9375rem;
  margin-top: 13px;
  margin-bottom: 11px;
}

.new-matches-card .location {
  display: block;
  font-size: 0.75em;
  line-height: 0.875rem;
}

.new-matches-card .skills-row {
  background-color: #e8eef2;
  padding: 20px;
  margin-bottom: 12px;
  color: var(--color-happy-days);
}

.new-matches-card .skills-row .skill {
  color: var(--color-halite-blue);
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.new-matches-card .education-row {
  color: #4d565c;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
}

.new-matches-card .education-row .degree {
  text-align: center;
  font-size: 0.8125rem;
  line-height: 1rem;
}

.new-matches-card .education-row .institution {
  text-align: center;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1rem;
  margin-bottom: 18px;
}

.new-matches-card .last-row {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.new-matches-card .last-row .titles span {
  display: block;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.75rem;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 6px;
}

.new-matches-card .last-row .values div {
  flex-grow: 1;
  flex-basis: 0;
}

.new-matches-card .last-row .values .star-icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-matches-card .last-row .values .star-icon {
  width: 22px;
  height: 22px;
  margin: 0 2px;
}

.new-matches-card .last-row .values .star-icon.active {
  fill: var(--color-super-rare-jade);
}

.new-matches-card .last-row .values .experience {
  font-family: "Lato", sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #13b9b7;
}
