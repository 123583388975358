.candidates-stats__pie-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidates-stats__pie-chart-tooltip {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 6px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 13px;
  z-index: 999999;
}
