.toggle-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 48px;
  height: 22px;
  background: var(--color-halite-blue);
  display: block;
  border-radius: 100px;
  position: relative;
}

.toggle-switch label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 18px;
  height: 18px;
  background: var(--color-suez-canal);
  border-radius: 24px;
  transition: 0.3s;
}

.toggle-switch input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggle-switch label:active:after,
.toggle-switch label:hover:after {
  width: 24px;
}

.toggle-switch input:checked + label:after {
  background: var(--color-super-rare-jade);
}
