.option-group {
  color: var(--color-gloomy-sea);
  padding-bottom: 72px;
}

.option-group .title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625rem;
  letter-spacing: 0.9px;
  margin-bottom: 22px;
}

.option-group label:not(:last-of-type) {
  margin-bottom: 22px;
}

.pulse {
  animation: pulse-animation 1s infinite;
}
@keyframes pulse-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wiggle-once {
  animation: wiggle 1.5s forwards;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(4deg);
  }
  25% {
    transform: rotate(-4deg);
  }
  35% {
    transform: rotate(0deg);
  }
}
