.horizontal-graph-tooltip {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0 10px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 13px;
  z-index: 999999;
}

.horizontal-graph-tooltip .month {
  display: block;
  color: var(--color-white);
  padding: 10px 0 6px 0;
}

.horizontal-graph-tooltip .data span {
  display: block;
  padding-bottom: 5px;
}
