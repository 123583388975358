.forgot-password-container {
  padding: 60px 0;
}

.forgot-password-container .title-section {
  padding-bottom: 30px;
}

.forgot-password-container .title-section h3 {
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
}

.forgot-password-container form {
  width: 90%;
  max-width: 600px;
}
