.candidate-list-item {
  font-family: "Roboto", sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  color: var(--color-happy-days);
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px var(--color-blissful-blue);
  overflow: hidden;
  -webkit-transition: border 0.2s ease-in-out;
  -moz-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  -ms-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  -ms-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
}

@media only screen and (min-width: 1170px) {
  .candidate-list-item:hover {
    box-shadow: 0px 0px 0px 3px var(--color-super-rare-jade);
  }
}

.candidate-list-item .personal-info-row {
  align-self: flex-start;
  padding: 16px 0;
}

.candidate-list-item .hired-date-row {
  align-items: flex-end;
  flex-grow: 1;
}

.candidate-list-item .photo-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 24px;
}

.candidate-list-item .experience {
  display: block;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 0.8125rem;
  letter-spacing: 0.6px;
  color: var(--color-boyzone);
}

.candidate-list-item .chevron {
  position: absolute;
  top: 72px;
  right: -4px;
  z-index: 1;
}

.candidate-list-item .first-name,
.candidate-list-item .last-name {
  display: block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.0625rem;
  margin-bottom: 3px;
}

.candidate-list-item .title {
  display: block;
  font-size: 0.8125em;
  font-weight: 500;
  line-height: 0.9375rem;
  margin-top: 13px;
  margin-bottom: 4px;
}

.candidate-list-item .location {
  display: block;
  font-size: 0.75em;
  line-height: 0.875rem;
}

.candidate-list-item .hired-date {
  display: block;
  width: 124px;
  font-size: 0.625em;
  line-height: 0.8125rem;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: var(--color-ending-navy-blue);
}

.candidate-list-item .action-icon,
.candidate-list-item .briefcase-icon,
.candidate-list-item .favorite-icon__container,
.candidate-list-item .status-icon__container {
  width: 18px;
  height: 18px;
}

.candidate-list-item .favorite-icon__container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.candidate-list-item .briefcase-icon {
  fill: var(--color-super-rare-jade);
}

.candidate-list-item .action-icon.selected {
  fill: var(--color-brig);
}

.candidate-list-item .social-icons {
  display: flex;
  align-items: flex-end;
}

.candidate-list-item .social-icons > svg,
.candidate-list-item .social-icons > a,
.candidate-list-item .social-icons > a > svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
