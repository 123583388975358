.option {
  position: relative;
  display: block;
  font-size: 1rem;
  letter-spacing: 0.6px;
  color: var(--color-gloomy-sea);
  user-select: none;
  display: flex;
  align-items: center;
}

.option input {
  position: relative;
  margin-right: 18px;
  appearance: none;
  background-color: transparent;
  border: 2px solid var(--color-blissful-blue);
  padding: 11px;
  border-radius: 3px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.option input:active,
.option input:checked,
.option input:hover,
.option input:checked:active {
  border-color: var(--color-gloomy-sea);
}

.option input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;

  top: 3px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid var(--color-gloomy-sea);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.option input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--color-gloomy-sea);
}
