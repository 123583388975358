.upcoming-interview-list-item {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  position: relative;
  margin: 0px 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--color-phenomenon);
  font-size: 1rem;
}

.upcoming-interview-list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upcoming-interview-list-item__name {
  width: 75%;
  font-size: 0.9375em;
  line-height: 1.0625rem;
  font-weight: 600;
  letter-spacing: 0.38px;
}

.upcoming-interview-list-item__date {
  text-align: right;
  color: var(--color-white);
  font-size: 0.6875rem;
  line-height: 1rem;
}

.upcoming-interview-list-item__description {
  color: var(--color-first-frost);
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.3px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.upcoming-interview-list-item__link {
  display: flex;
  color: var(--color-white);
  font-size: 0.625rem;
  line-height: 0.9375rem;
  letter-spacing: 0.25px;
  margin-top: 12px;
  margin-bottom: 0;
  text-decoration: underline;
}

.upcoming-interview-list-item:last-of-type {
  border-bottom: 0;
}
