.ai-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 93, 132, 0.5);
  z-index: 1;
  overflow: auto;
}

.ai-modal .content {
  background-color: white;
  max-width: 420px;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
  border-color: var(--color-blissful-blue);
  border-width: 1px;
  border-style: solid;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
}

.ai-modal h1 {
  font-family: "Roboto", sans-serif;
  color: var(--color-suez-canal);
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.0625rem;
  margin-top: 0;
  margin-bottom: 17px;
}

.ai-modal p {
  font-family: "Roboto", sans-serif;
  color: var(--color-submarine-grey);
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  line-height: 0.9375rem;
  margin-top: 0;
  margin-bottom: 22px;
}
